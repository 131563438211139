<template>
    <div class="container_con">
        <el-card v-if="form" >
            <div class="con_title"><span></span> 盘点单详情</div>
            <div style="line-height: 24px; font-size: 14px;">
                <el-row :gutter="20" style="margin-bottom: 10px;">
                    <el-col :span="20">盘点日期：{{form.inventory_date}}</el-col>
                </el-row>
                <el-row :gutter="20" style="margin-bottom: 10px;">
                  <el-col :span="20">单据编号：{{form.inventory_no}}</el-col>
                </el-row>
                <el-row :gutter="20" style="margin-bottom: 10px;">
                  <el-col :span="20">部门：{{form.department_name}}</el-col>
                </el-row>
                <el-row :gutter="20" style="margin-bottom: 10px;">
                  <el-col :span="20">业务员：{{form.admin_name}}</el-col>
                </el-row>
                <el-row :gutter="20">
                    <el-col :span="20">仓库货架：{{form.warehouse_name}}</el-col>
                </el-row>
            </div>
            
            <div class="" style="margin-top: 10px; font-size: 14px;">
                <div style="padding-bottom: 20px;">盘点产品：</div>
                <div class="">
                    <el-table :data="form.inventory_product" style="width: 100%" >
                      <el-table-column label="产品编号">
                          <template v-slot="scope">
                            {{scope.row.product.product_no}}
                          </template>
                      </el-table-column>
                      <el-table-column label="品名">
                          <template v-slot="scope">
                            {{scope.row.product.product_name}}
                          </template>
                      </el-table-column>
                      <el-table-column label="型号规格">
                          <template v-slot="scope">
                            {{scope.row.product.model}}
                          </template>
                      </el-table-column>
                      <el-table-column label="批号">
                          <template v-slot="scope">
                            {{scope.row.batch_number}}
                          </template>
                      </el-table-column>
                      
                      <el-table-column label="有效期">
                          <template v-slot="scope">
                            {{scope.row.valid_until || '长效期'}}
                          </template>
                      </el-table-column>
                      <el-table-column label="账面数量">
                          <template v-slot="scope">
                            {{scope.row.book_amount}}
                          </template>
                      </el-table-column>
                      <el-table-column label="实际数量">
                          <template v-slot="scope">
                            {{scope.row.actual_amount}}
                          </template>
                      </el-table-column>
                      
                    </el-table>
                </div>
            </div>
            
            <div class="bottom_btn" style="justify-content: flex-start;">
                <el-button @click="$router.go(-1)">返回</el-button>
            </div>
        </el-card>
        
    </div>
</template>

<script>
    // 上传组件
    export default {
        name: "blitemDetail",
        data() {
            return {
                form: '',
                // 上传图片
                uploadImageUrl: '',
                uploadImgVisible: false,
            };
        },
        created() {
            this.id = this.$route.query.id;
            this.get_data()
        },
        methods: {
            PicturePreview(file) {
                this.uploadImageUrl = file.url;
                this.uploadImgVisible = true;
            },
            get_data() {
                this.$httpGet("/backend/Inventory/read", {
                    id: this.id
                }).then((res) => {
                    if (res.status == 200) {
                        let _data = res.data;
                        this.form = _data;
                    } else {
                        this.$message.error(res.message);
                    }
                }).catch((err) => {
                    console.log(err);
                });
            },
        },
    };
</script>

<style lang="scss" scoped>
    .items {
        width: 100%;
        padding-right: 30px;
    }

    .items .item {
        padding: 10px;
        width: 100%;
        padding-right: 30px;
        line-height: 24px;
        color: #333;
        font-size: 14px;
    }

    .items .item .tt {
        color: #888;
        width: 150px
    }

    .avatar {
        height: 80px;
    }
</style>
